/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/FieldWrapper';
import { Field } from 'formik';
import { getFieldLabel } from '../../lib';
import { Popover, Text, TextField } from '@nike/eds';
import {
  isCategoryField,
  QuestionSetTypes,
} from '../../RepeatableFieldsetUtils';
import {
  customVariableSupportText,
  unknownCustomVariableError,
} from 'components/forms/utils';
import { validateCustomInquiryVariables } from '../../utils';
import styles from './index.module.scss';
import { GuideModal } from '../GuideModal';

export const TextComponent = ({
  fieldId,
  fieldLabel,
  fieldValue,
  fieldName,
  fieldType,
  fieldHelperText,
  className,
  placeholder,
  disabled,
  hasErrors,
  requiredFields,
  onChange,
  errorMessage,
}) => {
  const [popoverShown, setPopoverShown] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [inquiryVariables, setInquiryVariables] = useState([]);

  const handlePopoverClick = useCallback(
    () => setPopoverShown((prev) => !prev),
    []
  );

  useEffect(() => {
    const variables = sessionStorage.getItem('inquiryVariables');
    if (variables) {
      setInquiryVariables(JSON.parse(variables));
    }
  }, []);

  return (
    <Field
      key={ fieldId }
      name={ fieldName }
      placeholder={ placeholder || fieldName }
      className={ className }
    >
      { ({ field, meta }) => {
        useEffect(() => {
          if (inquiryVariables.length > 0) {
            const isInvalidInquiryVariable = validateCustomInquiryVariables(
              meta.touched ? field.value || fieldValue : fieldValue || '',
              inquiryVariables
            );
            if (meta.touched && isInvalidInquiryVariable) {
              setShowWarning(true);
            } else setShowWarning(false);
          }
        }, [meta.touched, fieldValue, field.value, inquiryVariables]);

        const isCategory = isCategoryField(fieldName?.split('-')[0]);
        const showError =
          !!(meta.touched && hasErrors) ||
          (isCategory && fieldValue?.length > 0 && hasErrors && errorMessage);
        const errorMsg =
          (meta.touched && hasErrors && errorMessage) ||
          (isCategory && fieldValue?.length > 0 && hasErrors && errorMessage);

        return (
          <FieldWrapper disabled={ disabled } className={ className }>
            <div className={ showWarning ? styles.rowContainer : '' }>
              <TextField
                hasErrors={ showError }
                errorMessage={ errorMsg }
                disabled={ disabled }
                key={ fieldId }
                type={ fieldType }
                id={ fieldName }
                name={ fieldName }
                defaultValue={ fieldValue || '' }
                className={ className }
                value={
                  meta.touched ? field.value || fieldValue : fieldValue || ''
                }
                label={
                  <label>
                    { fieldLabel || getFieldLabel(field.name) }
                    { (requiredFields[fieldName] ||
                      fieldName.includes(QuestionSetTypes.QUESTION_LABEL) ||
                      fieldName.includes(QuestionSetTypes.CATEGORY_NAME)) &&
                      !(meta.touched && hasErrors) && (
                      <span className="asterisk">*</span>
                    ) }
                  </label>
                }
                message={
                  fieldHelperText === customVariableSupportText ? (
                    <GuideModal />
                  ) : (
                    fieldHelperText
                  )
                }
                onChange={ onChange }
              />
              { inquiryVariables.length > 0 && showWarning && (
                <div className={ styles.warningPopover }>
                  <Popover
                    onClickOutside={ handlePopoverClick }
                    isOpen={ popoverShown }
                    bodySlot={
                      <Text className={ styles.popoverText } font="body-3">
                        { unknownCustomVariableError }
                      </Text>
                    }
                    headerSlot={ <Text font="title-5">Warning</Text> }
                    placement="bottom"
                  >
                    <div
                      className={ styles.warningIcon }
                      onClick={ handlePopoverClick }
                    >
                      ⚠️
                    </div>
                  </Popover>
                </div>
              ) }
            </div>
          </FieldWrapper>
        );
      } }
    </Field>
  );
};

TextComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  fieldHelperText: PropTypes.string,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldType: PropTypes.string,
  fieldValue: PropTypes.string,
  hasErrors: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  requiredFields: PropTypes.shape(Object),
};
